<template>
  <div v-if="step == 1" class="settingStep">
    <div class="container" style="padding:10px;margin-bottom:20px;">
      <el-button type="primary" @click="printPreview">
        <el-icon>
          <document />
        </el-icon>
        Print Preview
      </el-button>
    </div>
    <div class="settingTemplate">
      <el-descriptions border title="Setting" direction="vertical" :column="4">
        <el-descriptions-item label="Classroom">
          <el-select
            v-model="form.roomId"
            placeholder="Please select..."
            @change="handleClassRoomChange"
          >
            <el-option
              v-for="item in classRoomList"
              :key="item.roomId"
              :label="item.name"
              :value="item.roomId"
            ></el-option>
          </el-select>
        </el-descriptions-item>
        <el-descriptions-item label="Bill To">
          <el-input v-model="form.billTo" class="studentInput"></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="Bill date">
          <el-date-picker
            type="date"
            placeholder="Select Bill Date"
            v-model="form.billDate"
          ></el-date-picker>
        </el-descriptions-item>
        <el-descriptions-item label="GST Rate(%)">
          <el-input
            type="number"
            :step="1"
            v-model="form.gst"
            @change="handleGSTRateChange"
          ></el-input
        ></el-descriptions-item>
        <el-descriptions-item label="Sibling Discount(%)">
          <el-input-number
            v-model="form.siblingDiscount"
            :min="0"
            :max="100"
            :step="1"
            controls-position="right"
            @change="handleSiblingDiscountChange"
          ></el-input-number>
        </el-descriptions-item>
        <el-descriptions-item label="Special Discount(%)">
          <el-input-number
            v-model="form.specialDiscount"
            :min="0"
            :max="100"
            :step="1"
            controls-position="right"
            @change="handleSpecialDiscountChange"
          ></el-input-number>
        </el-descriptions-item>
        <el-descriptions-item label="EarlyBird">
          <el-checkbox
            v-model="this.form.enabledEarlyBirdPromotionType"
            @change="addPromotionDiscount('EarlyBird', $event)"
          ></el-checkbox>
        </el-descriptions-item>
        <el-descriptions-item label="Discount(%)">
          <el-input-number
            :min="0"
            :max="100"
            :step="1"
            :disabled="true"
            v-model="form.discount"
            controls-position="right"
            @change="handleDiscountChange"
          ></el-input-number>
        </el-descriptions-item>
        <el-descriptions-item label="Enrollment Fee">
          <el-checkbox
            v-model="this.specialItemSwitch.EnrollmentFee"
            @change="handleSpecialItems('EnrollmentFee', $event)"
          ></el-checkbox>
        </el-descriptions-item>
        <el-descriptions-item label="Booklet Fee">
          <el-checkbox
            v-model="this.specialItemSwitch.BookletFee"
            @change="handleSpecialItems('BookletFee', $event)"
          ></el-checkbox>
        </el-descriptions-item>
        <el-descriptions-item label="Royal Points">
          <el-checkbox
            @change="handleEnableRoyalPointsChange"
            v-model="this.form.enableRoyalPoints"
          ></el-checkbox>
        </el-descriptions-item>
        <el-descriptions-item label="Enrolment Points(pending)">
          <el-checkbox
            @change="handleEnableEnrolmentPointsChange"
            v-model="this.form.enableEnrolmentPoints"
          ></el-checkbox>
        </el-descriptions-item>
      </el-descriptions>
      <div class="studentInfoPanel">
        RollDate: {{ this.$formatter.formatDate(this.student.enrolledTime) }},
        Royal Years: <b>{{ this.getLoyaltyYears() }}</b
        >.
      </div>
    </div>
    <div class="discountItems">
      <el-descriptions border title="Discount Items" direction="horizontal">
        <div v-for="item in this.form.discountItems" :key="item.name">
          <el-descriptions-item>
            <template #label>
              <el-icon>
                <calendar />
              </el-icon>
              {{ item.name }}
            </template>
            {{ item.discount }}%
          </el-descriptions-item>
        </div>
      </el-descriptions>
    </div>
    <div class="discountItems">
      <el-descriptions
        border
        title="Points Item to Be Issued"
        direction="horizontal"
      >
        <div v-for="item in this.form.pointsIssuingItems" :key="item.name">
          <el-descriptions-item>
            <template #label>
              <el-icon>
                <coin />
              </el-icon>
              {{ item.title }}
            </template>
            {{ item.points }}
            {{
              item.issuedType !== "Normal"
                ? `${item.issuedType} Points`
                : "Points"
            }}
          </el-descriptions-item>
        </div>
      </el-descriptions>
    </div>
    <div class="courseTemplate">
      <h2 class="el-descriptions__title" style="margin-top:20px">Courses</h2>
      <el-select
        v-model="needToAddCourse"
        value-key="courseId"
        class="courseSelect"
        placeholder="Add a course"
      >
        <el-option
          v-for="item in courseList"
          :key="item.courseId"
          :label="item.title"
          :value="item"
        ></el-option>
      </el-select>
      <el-button @click="handleAddCourse">
        <el-icon>
          <plus />
        </el-icon>
      </el-button>
      <el-table :data="this.form.items" border class="courseListTable">
        <el-table-column label="Description">
          <template #default="scope">
            <el-input
              v-model="scope.row.title"
              class="courseNameInput"
            ></el-input>
            <el-select
              v-model="scope.row.term"
              class="termSelect"
              placeholder="Select Term"
            >
              <el-option
                v-for="item in this.options.termList"
                :key="item.value"
                :label="item.description"
                :value="item.value"
              ></el-option>
            </el-select>
            <el-input
              v-model="scope.row.comment"
              class="commentInput"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="Class Hours" width="120">
          <template #default="scope">
            <el-input type="number" v-model="scope.row.quantity"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="Unit Price" width="120">
          <template #default="scope">
            <el-input type="number" v-model="scope.row.unitPrice"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="Subtotal (Incl.GST)" width="140">
          <template #default="scope">
            {{
              this.$formatter.formatDecimal(this.calculateItemTotal(scope.row))
            }}
          </template>
        </el-table-column>
        <el-table-column label="GST" width="90">
          <template #default="scope">
            {{
              this.$formatter.formatDecimal(this.calculateItemGST(scope.row))
            }}
          </template>
        </el-table-column>
        <el-table-column label="Operation" width="180" align="center">
          <template #default="scope">
            <el-button
              size="mini"
              @click="handleDeleteItem(scope.$index, scope.row)"
            >
              <el-icon> <delete /> </el-icon
            ></el-button>
            <el-button
              size="mini"
              @click="handleMoveUpItem(scope.$index, scope.row)"
            >
              <el-icon> <top /> </el-icon>
            </el-button>
            <el-button
              size="mini"
              @click="handleMoveDownItem(scope.$index, scope.row)"
            >
              <el-icon> <bottom /> </el-icon>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-descriptions border :column="1">
        <el-descriptions-item label="Total Subtotal">
          ${{ this.$formatter.formatDecimal(this.calculateNetTotal()) }}
        </el-descriptions-item>
        <el-descriptions-item label="Discount">
          ${{ this.$formatter.formatDecimal(this.calculateDiscountAmount()) }}
        </el-descriptions-item>
        <el-descriptions-item label="TOTAL (Round Up)">
          ${{ this.$formatter.formatDecimal(this.calculateTotal()) }}
        </el-descriptions-item>
      </el-descriptions>
    </div>
  </div>
  <div v-else class="printReviewStep">
    <div class="container" style="padding:10px;margin-bottom:20px;">
      <el-button type="primary" @click="previousStep">
        <el-icon>
          <back />
        </el-icon>
        Previous
      </el-button>
      <!-- <el-button type="primary" @click="printReceipt">
        <el-icon >
              <printer />
            </el-icon>
        Print
      </el-button> -->
      <el-button type="primary" @click="saveAndPrintReceipt">
        <el-icon>
          <printer />
        </el-icon>
        Save &amp; Print
      </el-button>
    </div>
    <div id="printArea" class="printArea">
      <div class="header">
        <div class="leftPart">
          <table border="0" cellpadding="0" cellspacing="0">
            <tr>
              <td class="companyName">{{ form.companyName }}</td>
            </tr>
            <tr>
              <td>{{ form.detailedAddress }}</td>
            </tr>
            <tr>
              <td>{{ form.suburb }}, {{ form.postcode }}</td>
            </tr>
            <tr>
              <td>Phone: {{ form.phone }}</td>
            </tr>
            <tr>
              <td>ABN: {{ form.abn }}</td>
            </tr>
            <tr>
              <td>Website: {{ form.website }}</td>
            </tr>
          </table>
        </div>
        <div class="rightPart">
          <div class="templateAndLog">
            <div class="templateName">{{ form.templateName }}</div>
            <div class="logo"><img src="../../assets/img/je_logo.png" /></div>
          </div>
          <div class="billToDate">
            DATE
            <span class="date">{{
              this.$formatter.formatDate(form.billDate)
            }}</span>
          </div>
        </div>
      </div>
      <div class="billTo">
        <div class="title" style="background-color: #3b4e87;">BILL TO</div>
        <div class="name">{{ form.billTo }}</div>
      </div>
      <div class="items">
        <table border="0" cellpadding="0" cellspacing="0">
          <tr>
            <th style="width:50%;background-color: #3b4e87;">DESCRIPTION</th>
            <th style="width:30%;background-color: #3b4e87;">
              Subtotal (Incl.GST)
            </th>
            <th style="width:20%;background-color: #3b4e87;">GST</th>
          </tr>
          <tr
            v-for="(item, index) in form.items"
            :key="index"
            :class="index % 2 == 0 ? '' : 'odd'"
          >
            <td>
              {{ item.title }}
              {{ item.term ? `, ${item.term}` : "" }}
              {{ item.comment ? `, ${item.comment}` : "" }}
            </td>
            <td>{{ this.$formatter.formatDecimal(item.total) }}</td>
            <td>{{ this.$formatter.formatDecimal(item.gstAmount) }}</td>
          </tr>
          <tr
            v-for="n in 16 - form.items.length"
            :key="n"
            :class="(n + 1 + form.items.length) % 2 == 0 ? '' : 'odd'"
          >
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </table>
      </div>

      <div class="summary">
        <div class="leftPart">
          <div class="title" style="background: #3b4e87;">OTHER COMMENTS</div>
          <ul>
            <li v-for="(item, index) in form.comments" :key="index">
              {{ index + 1 }}. {{ item }}
            </li>
          </ul>
        </div>
        <div class="rightPart">
          <table border="0" cellpadding="0" cellspacing="0">
            <tr>
              <td>Total Subtotal</td>
              <td class="value">
                ${{ this.$formatter.formatDecimal(form.netTotal) }}
              </td>
            </tr>
            <tr>
              <td class="discount">Discount</td>
              <td class="discount value">
                ${{ this.$formatter.formatDecimal(form.discountAmount) }}
              </td>
            </tr>
            <tr>
              <td class="total">TOTAL</td>
              <td
                class="total value"
                style="background-color:#C6EFCE;color:#006100;"
              >
                ${{ this.$formatter.formatDecimal(form.total) }}
              </td>
            </tr>
          </table>

          <!-- <div class="paymentMethods">
            <div class="title" style="background: #3b4e87;">
              Payment Methods
            </div>
            <ul>
              <li v-for="(item, index) in form.paymentMethods" :key="index">
                {{ index + 1 }}. {{ item }}
              </li>
            </ul>
          </div> -->
        </div>
      </div>
      <div class="footer">
        <div class="container">
          <div class="contactDetail">{{ form.contact }}</div>
          <div class="compliment">{{ form.compliment }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getData, putData, postData } from "../../service/api";

export default {
  name: "buildReceiptTemplate",
  data() {
    return {
      controllerUrl: "/receipt",
      step: 1,
      options: {
        termList: [],
        pointsTemplateList: [],
      },
      needToAddCourse: "",
      promotionItems: [
        {
          name: "Normal",
          isSuccessive: false,
          discount: 0,
        },
        {
          name: "EarlyBird",
          isSuccessive: false,
          discount: 15,
        },
      ],
      specialItemSwitch: {},
      specialItems: [
        {
          id: "EnrollmentFee",
          title: "Enrollment fee",
          type: "Enrollment",
          useGlobalTerm: true,
          term: "",
          unitPrice: 20,
          quantity: 1,
          comment: "",
          useGlobalGst: false,
          gst: 0,
          useGlobalDiscount: false,
          useGlobalSuccessiveDiscount: false,
          successiveDiscount: 0,
          discount: 0,
        },
        {
          id: "BookletFee",
          title: "Booklet fee",
          type: "Booklet",
          useGlobalTerm: true,
          term: "",
          unitPrice: 20,
          quantity: 1,
          comment: "15% Off",
          useGlobalGst: false,
          gst: 0,
          useGlobalDiscount: false,
          useGlobalSuccessiveDiscount: false,
          successiveDiscount: 0,
          discount: 15,
        },
      ],
      classRoomList: [],
      courseList: [],
      student: {},
      form: {
        receiptId: "",
        studentId: "",
        studentName: "",
        roomId: "",
        roomName: "",
        grade: "",
        term: "",
        gst: 10,
        enabledEarlyBirdPromotionType: false,
        enableRoyalPoints: true,
        enableEnrolmentPoints: true,
        loyaltyYears: 0,
        loyalDiscount: 0,
        siblingDiscount: 0,
        specialDiscount: 0,
        successiveDiscount: 0, // siblingDiscount+specialDiscount+loyalDiscount
        discount: 0,
        discountItems: [],
        pointsIssuingItems: [],
        promotionType: "Normal",
        operator: "",
        companyLogo: "../../assets/img/je_logo.png",
        templateName: "RECEIPT",
        companyName: "JE Maths Education",
        detailedAddress: "Suite 25/1 Railway Parade",
        suburb: "Burwood",
        postCode: "2134",
        phone: "0422777073",
        abn: "45169016274",
        website: "http://www.jeeducation.com.au",
        billDate: new Date(),
        billTo: "",
        items: [],
        description: "",
        note: "",
        netTotal: 0, // without gst and discount amount
        discountAmount: 0,
        gstTotal: 0,
        total: 0, // include gst + discount amount
        comments: ["No Refund! No Credit! "],
        paymentMethods: ["By Cash.", "By Eftpos."],
        contact:
          "If you have any questions about this receipt, please contact [Jonathan Hu, Phone 0422777073, jemathseducation@gmail.com]",
        compliment: "Thank You For Your Business!",
      },
    };
  },
  watch: {
    "$route.query"(newValue) {
      console.log("this.$route.query", newValue);
      this.previousStep();
      this.initAndLoadData(newValue);
    },
  },
  created() {
    this.options.pointsTemplateList = this.$appSetting.globalConfig.pointsTemplateList;
    this.options.termList = this.$appSetting.globalConfig.termList;
    this.initAndLoadData(this.$route.query);
  },
  methods: {
    async initAndLoadData(query) {
      let studentId = query.studentId;
      let receiptId = query.receiptId;
      let roomId = query.roomId;

      for (let i = 0; i < this.specialItems.length; i++) {
        this.specialItemSwitch[this.specialItems[i].id] = false;
      }

      await this.loadBasicData();
      if (roomId && roomId.length == 22) {
        this.handleClassRoomChange(roomId);
      }
      if (receiptId && receiptId.length == 22) {
        this.form.receiptId = receiptId;
        this.loadData();
      } else if (studentId && studentId.length == 8) {
        this.form.studentId = studentId;
        this.loadData();
      }
    },
    loadData() {
      if (this.form.receiptId) {
        this.loadReceiptData();
      } else if (this.form.studentId) {
        this.loadStudentData();
      }
    },
    async loadBasicData() {
      await this.loadAllClassRooms();
      await this.loadCourseData();
    },
    loadReceiptData() {
      getData(`${this.controllerUrl}/${this.form.receiptId}`, null)
        .then((res) => {
          if (res.result && res.code === "200") {
            for (let pro in res.result) {
              this.form[pro] = res.result[pro];
            }
            for (let i = 0; i < this.form.items.length; i++) {
              if (this.specialItemSwitch[this.form.items[i].id] === false) {
                this.specialItemSwitch[this.form.items[i].id] = true;
              }
            }
            let siblingDiscountItem = this.form.discountItems.find((e) => {
              return e.name === "SiblingDiscount";
            });
            if (siblingDiscountItem) {
              this.form.siblingDiscount = siblingDiscountItem.discount;
            }
            let specialDiscountItem = this.form.discountItems.find((e) => {
              return e.name === "SpecialDiscount";
            });
            if (specialDiscountItem) {
              this.form.specialDiscount = specialDiscountItem.discount;
            }
            let earlyBirdPromotionType = this.form.discountItems.find((e) => {
              return e.name === "EarlyBird";
            });
            if (earlyBirdPromotionType) {
              this.form.enabledEarlyBirdPromotionType = true;
            }
            if (res.result.studentId) {
              this.form.studentId = res.result.studentId;
              this.loadStudentData();
            }
          } else {
            this.$message.error(
              "Fetch student data failed, error message: " + res.message
            );
          }
        })
        .catch(() => {});
    },
    loadStudentData() {
      getData(`/student/${this.form.studentId}`, null)
        .then((res) => {
          if (res.result && res.code === "200") {
            this.student = res.result;
            if (!this.form.receiptId) {
              this.addLoyaltyYearsDiscount();
              this.form.studentName = res.result.username;
              this.form.billTo = `${res.result.firstName} ${res.result.lastName}`;
              this.handleEnableRoyalPointsChange();
              this.handleEnableEnrolmentPointsChange();
            }
          } else {
            this.$message.error(
              "Fetch student data failed, error message: " + res.message
            );
          }
        })
        .catch(() => {});
    },
    async loadCourseData() {
      let res = await getData("/course", {
        orderBy: "CreatedTime",
        orderDirection: "ASC",
        pageIndex: 1,
        pageSize: 0x7fffffff,
      });
      if (res) {
        let resData = res.result;
        this.courseList = resData.list;
      }
    },
    async loadAllClassRooms() {
      let res = await getData("classRoom/getAllClassRoomIdAndNameForReceipt");
      if (res) {
        this.classRoomList = res.result;
      }
    },
    handleClassRoomChange(roomId) {
      console.log("handleClassRoomChange", arguments);
      let classRoom = this.classRoomList.find((e) => e.roomId === roomId);
      if (classRoom) {
        this.form.roomId = classRoom.roomId;
        this.form.grade = classRoom.grade;
        this.form.term = classRoom.term;
      }
    },
    handleGSTRateChange() {
      for (let i = 0; i < this.form.items.length; i++) {
        let item = this.form.items[i];
        let specialItem = this.specialItems.find((e) => {
          return e.id === item.id;
        });
        if (!specialItem || specialItem.useGlobalGst) {
          item.gst = this.form.gst;
        }
      }
    },
    handleDiscountChange() {
      for (let i = 0; i < this.form.items.length; i++) {
        let item = this.form.items[i];
        let specialItem = this.specialItems.find((e) => {
          return e.id === item.id;
        });
        if (!specialItem || specialItem.useGlobalDiscount) {
          item.discount = this.form.discount;
        }
      }
    },
    handleSiblingDiscountChange() {
      const siblingDiscountName = "SiblingDiscount";
      let siblingDiscount = this.form.siblingDiscount;
      this.addOrUpdateSuccessiveDiscountItem(
        siblingDiscountName,
        true,
        siblingDiscount
      );
    },
    handleSpecialDiscountChange() {
      const specialDiscountName = "SpecialDiscount";
      let specialDiscount = this.form.specialDiscount;
      this.addOrUpdateSuccessiveDiscountItem(
        specialDiscountName,
        true,
        specialDiscount
      );
    },
    addLoyaltyYearsDiscount() {
      const royalDiscountName = "RoyalDiscount";
      let LoyaltyYears = this.getLoyaltyYears();
      this.form.loyalDiscount = LoyaltyYears;
      this.addOrUpdateSuccessiveDiscountItem(
        royalDiscountName,
        true,
        LoyaltyYears
      );
    },
    handleSpecialItems(id, addIt) {
      console.log("handleSpecialItems", arguments);
      if (!addIt) {
        this.form.items = this.form.items.filter((item) => {
          return item.id !== id;
        }, this);
        return;
      }
      let specialItem = this.specialItems.find((elem) => {
        return elem.id === id;
      });
      if (specialItem) {
        let item = {
          id: specialItem.id,
          title: specialItem.title,
          type: specialItem.type,
          term: specialItem.useGlobalTerm ? this.form.term : specialItem.term,
          unitPrice: specialItem.unitPrice,
          quantity: specialItem.quantity,
          comment: specialItem.comment,
          gst: specialItem.useGlobalGst ? this.form.gst : specialItem.gst,
          successiveDiscount: specialItem.useGlobalSuccessiveDiscount
            ? this.form.successiveDiscount
            : specialItem.successiveDiscount,
          discount: specialItem.useGlobalDiscount
            ? this.form.discount
            : specialItem.discount,
        };

        this.form.items.push(item);
      }
    },
    handleAddCourse() {
      console.debug("needToAddCourse", this.needToAddCourse);
      let comment = this.form.promotionType;
      if (!this.needToAddCourse) return;
      let course = this.needToAddCourse;
      let item = {
        id: course.courseId,
        title: course.title,
        type: "Course",
        term: this.form.term,
        unitPrice: course.unitPrice,
        quantity: course.classHours,
        comment: comment,
        gst: this.form.gst,
        successiveDiscount: this.form.successiveDiscount,
        discount: this.form.discount,
      };

      this.form.items.push(item);
    },
    handleDeleteItem(index) {
      let items = this.form.items.splice(index, 1);
      if (items.length > 0) {
        this.specialItemSwitch[items[0].id] = false;
      }
    },
    handleMoveUpItem(index) {
      if (index <= 0) return;
      let delItems = this.form.items.splice(index, 1);
      this.form.items.splice(index - 1, 0, delItems[0]);
    },
    handleMoveDownItem(index) {
      if (index >= this.form.items.length - 1) return;
      let delItems = this.form.items.splice(index, 1);
      this.form.items.splice(index + 1, 0, delItems[0]);
    },
    calculateItemTotal(item) {
      item.total = item.unitPrice * item.quantity;
      return item.total;
    },
    calculateItemGST(item) {
      item.gstAmount = item.unitPrice * item.quantity * (0.01 * item.gst);
      return item.gstAmount;
    },
    calculateNetTotal() {
      let netTotal = 0;
      for (let i = 0; i < this.form.items.length; i++) {
        let item = this.form.items[i];
        netTotal += item.unitPrice * item.quantity;
      }
      this.form.netTotal = netTotal;
      return this.form.netTotal;
    },
    calculateDiscountAmount() {
      let discountAmount = 0;
      for (let i = 0; i < this.form.items.length; i++) {
        let item = this.form.items[i];
        discountAmount =
          discountAmount +
          item.unitPrice * item.quantity * (0.01 * item.discount) +
          item.unitPrice *
            item.quantity *
            (1 - 0.01 * item.discount) *
            (0.01 * item.successiveDiscount);
      }
      this.form.discountAmount = discountAmount;
      return this.form.discountAmount;
    },
    calculateTotal() {
      let total = 0;
      let gstTotal = 0;
      for (let i = 0; i < this.form.items.length; i++) {
        let item = this.form.items[i];
        total +=
          item.unitPrice *
          item.quantity *
          (1 - 0.01 * item.discount) *
          (1 - 0.01 * item.successiveDiscount);
        gstTotal += item.unitPrice * item.quantity * (0.01 * this.form.gst);
      }
      this.form.total = Math.round(total);
      this.form.gstTotal = gstTotal;
      return this.form.total;
    },
    printPreview() {
      if (this.validateData()) {
        this.step = 2;
      } else {
        this.$message.error("Validation failed, please correct!");
        return false;
      }
    },
    validateData() {
      return true;
    },
    previousStep() {
      this.step = 1;
    },
    addPromotionDiscount(promotionType, checked) {
      console.log("addPromotionDiscount", promotionType, checked);
      let promotion = this.promotionItems.find((e) => {
        return e.name === promotionType;
      });

      if (promotion) {
        this.addOrRemoveDiscountItem(
          promotion.name,
          promotion.isSuccessive,
          checked ? promotion.discount : 0
        );
        if (checked === true) {
          this.form.promotionType = promotion.name;
          this.form.discount += promotion.discount;
        } else {
          this.form.promotionType = "";
          this.form.discount -= promotion.discount;
          if (this.form.discount < 0) this.form.discount = 0;
        }

        this.handleDiscountChange();
      }
    },
    addOrUpdateSuccessiveDiscountItem(name, isSuccessive, discount) {
      this.addOrRemoveDiscountItem(name, isSuccessive, discount);
      this.form.successiveDiscount = 0;
      for (let i = 0; i < this.form.discountItems.length; i++) {
        let discountItem = this.form.discountItems[i];
        if (discountItem.isSuccessive === true) {
          this.form.successiveDiscount += discountItem.discount;
        }
      }

      for (let i = 0; i < this.form.items.length; i++) {
        let item = this.form.items[i];
        let specialItem = this.specialItems.find((e) => {
          return e.id === item.id;
        });
        if (!specialItem || specialItem.useGlobalSuccessiveDiscount) {
          item.successiveDiscount = this.form.successiveDiscount;
        }
      }
    },
    addOrRemoveDiscountItem(name, isSuccessive, discount) {
      this.form.discountItems = this.form.discountItems || [];
      let tempArr = this.form.discountItems.filter((element) => {
        return element.name == name;
      });
      if (tempArr.length > 0) {
        tempArr[0].discount = discount;
      } else {
        this.form.discountItems.push({
          name: name,
          isSuccessive: isSuccessive,
          discount: discount,
        });
      }
      if (discount <= 0) {
        let index = this.form.discountItems.findIndex((element) => {
          return element.name == name;
        });
        if (index >= 0) {
          this.form.discountItems.splice(index, 1);
        }
      }
    },
    saveAndPrintReceipt() {
      this.form.operator = this.$user.getUsername();
      let courseArr = [];
      this.form.items.forEach((item) => {
        courseArr.push(
          `${item.title}/${item.term}/${item.quantity}h/$${item.unitPrice}`
        );
      });
      this.form.description = courseArr.join("|");
      if (this.form.receiptId) {
        putData(this.controllerUrl, this.form.receiptId, this.form).then(
          (res) => {
            console.log(res);
            if (res.result && res.code === "200") {
              this.$message.info("Save receipt successfully");
              this.printReceipt();
            } else {
              this.$message.error(
                "Update failed, error message: " + res.message
              );
            }
          }
        );
      } else {
        postData(this.controllerUrl, this.form).then((res) => {
          console.log(res);
          if (res.result && res.code === "200") {
            this.$message.info("Save receipt successfully");
            this.printReceipt();
          } else {
            this.$message.error("Create failed, error message: " + res.message);
          }
        });
      }
    },
    getLoyaltyYears(enrolledTime) {
      let enrolledDate = new Date(enrolledTime || this.student.enrolledTime);
      let now = new Date();
      let diffInTime = now.getTime() - enrolledDate.getTime();
      let diffInDays = diffInTime / (1000 * 3600 * 24);
      this.form.loyaltyYears = Math.floor(diffInDays / 365);
      return this.form.loyaltyYears;
    },
    printReceipt() {
      const prtHtml = document.getElementById("printArea").outerHTML;
      let title = `${this.form.billTo}-${
        this.form.term
      }-${this.$formatter.formatDate(this.form.billDate, "yyyyMMdd")}`;
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
        <html>
          <head>
          <title>${title}</title>
<style>
@media print {
  body {
    margin:0;
    -webkit-print-color-adjust: exact;
  }
}
* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.printArea {
  width: 21cm;
  min-height: 29.7cm;
  padding: 1cm;
  margin: 1cm auto;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: white;
  font-size: 12px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
.printArea .header {
  width: 100%;
  background-color: #fff;
}
.printArea .header .leftPart {
  float: left;
}
.printArea .header .leftPart .companyName {
  color: #2c3a65;
  font-size: 28px;
  line-height: 47px;
}
.printArea .header .rightPart {
  float: right;
}
.printArea .header .rightPart .templateName {
  float: left;
  color: #7a8dc5;
  font-size: 34px;
  font-weight: 700;
  line-height: 48px;
}
.printArea .header .rightPart .logo {
  float: left;
}
.printArea .header .rightPart .logo img {
  width: 60px;
  height: 58px;
  margin-left: 10px;
}
.printArea .header .rightPart .billToDate {
  margin-top: 20px;
  float: right;
}
.printArea .header .rightPart .billToDate .date {
  color: #000;
  text-align: center;
  border: 0.5pt solid #a6a6a6;
  padding: 2px 20px;
}
.printArea .billTo {
  width: 100%;
  margin-top: 40px;
}
.printArea .billTo .title {
  color: white;
  font-size: 13px;
  font-weight: 700;
  text-align: left;
  background: #3b4e87;
  padding-left: 10px;
  width: 35%;
}
.printArea .billTo .name {
  padding-left: 10px;
}

.printArea .items {
  margin-top: 60px;
}
.printArea .items table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  border: 0.5pt solid #3b4e87;
  border-right-width: 0;
}

.printArea .items table tr {
  line-height: 20px;
  height: 20px;
}
.printArea .items table tr th {
  color: white;
  font-size: 13pt;
  font-weight: 700;
  text-align: center;
  background: #3b4e87;
  border-right: 0.5pt solid #3b4e87;
}
.printArea .items table tr td {
  text-align: center;
  border-right: 0.5pt solid #3b4e87;
  font-weight: 400;
}
.printArea .items table tr.odd {
  background: #f2f2f2;
}
.printArea .summary {
  margin-top: 40px;
  width: 100%;
}
.printArea .summary .leftPart {
  float: left;
  width: 45%;
  height: 60px;
  border-collapse: collapse;
  table-layout: fixed;
  border: 0.5pt solid #3b4e87;
}
.printArea .summary .leftPart .title {
  color: white;
  font-size: 13pt;
  font-weight: 700;
  padding-left: 10px;
  background: #3b4e87;
}
.printArea .summary .leftPart ul {
  padding-left: 10px;
  list-style-type: none;
}
.printArea .summary .rightPart {
  float: right;
  width: 40%;
  border-top: 0.5pt solid #3b4e87;
  height: 148px;
}
.printArea .summary .rightPart table {
  width: 100%;
}
.printArea .summary .rightPart table tr td {
  width: 50%;
  font-size: 12pt;
  font-weight: 400;
}
.printArea .summary .rightPart table tr td.value {
  text-align: right;
}
.printArea .summary .rightPart table tr td.discount {
  font-size: 18px;
  font-weight: 700;
}
.printArea .summary .rightPart table tr td.total {
  font-size: 20px;
  font-weight: 700;
}
.printArea .summary .rightPart .paymentMethods {
  width: 100%;
  margin-top: 20px;
  height: 60px;
  border: 0.5pt solid #3b4e87;
  display: none;
}
.printArea .summary .rightPart .paymentMethods .title {
  color: white;
  font-size: 13pt;
  font-weight: 700;
  padding-left: 10px;
  text-align: center;
  background: #3b4e87;
}
.printArea .summary .rightPart .paymentMethods ul {
  padding-left: 10px;
  list-style-type: none;
}
.printArea .footer {
  margin-top: 200px;
  width: 100%;
}
.printArea .footer .container {
  width: 45%;
  padding: 0;
  margin: 0 auto;
  border-width: 0;
  text-align: center;
}
.printArea .footer .contactDetail {
  width: 100%;
}
.printArea .footer .compliment {
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  font-style: italic;
  text-align: center;
}
.printArea .footer::after,
.printArea .summary::after,
.printArea .header::after,
.printArea .header .rightPart .templateAndLog::after {
  content: "";
  display: block;
  height: 0;
  font: 0px/0 serif;
  clear: both;
  overflow: hidden;
}
@page {
  size: A4;
  margin: 0;
}
@media print {
  .printArea {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }
}
</style>
          </head>
          <body>
            ${prtHtml}
          </body>
        </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      //WinPrint.close();
    },
    handleEnableRoyalPointsChange() {
      let loyaltyYears = this.form.loyaltyYears;
      let name = "LOYALTY_FOR_ONE_YEAR";
      if (loyaltyYears >= 5) {
        name = "LOYALTY_FOR_FIVE_YEAR";
      } else if (loyaltyYears == 4) {
        name = "LOYALTY_FOR_FOUR_YEAR";
      } else if (loyaltyYears == 3) {
        name = "LOYALTY_FOR_THREE_YEAR";
      } else if (loyaltyYears == 2) {
        name = "LOYALTY_FOR_TWO_YEAR";
      }
      if (this.form.enableRoyalPoints) {
        let item = this.options.pointsTemplateList.find((e) => e.name === name);
        if (item) {
          this.addPointsIssuingItem(item);
        }
      } else {
        this.removePointsIssuingItem(name);
      }
    },
    handleEnableEnrolmentPointsChange() {
      let name = "ENROLMENT";
      if (this.form.enableEnrolmentPoints) {
        let item = this.options.pointsTemplateList.find((e) => e.name === name);
        if (item) {
          this.addPointsIssuingItem(item);
        }
      } else {
        this.removePointsIssuingItem(name);
      }
    },
    addPointsIssuingItem(item) {
      if (
        this.form.pointsIssuingItems.findIndex((e) => e.name === item.name) >= 0
      )
        return;
      this.form.pointsIssuingItems.push(item);
    },
    removePointsIssuingItem(name) {
      let index = this.form.pointsIssuingItems.findIndex(
        (e) => e.name === name
      );
      if (index < 0) return;
      this.form.pointsIssuingItems.splice(index, 1);
    },
  },
};
</script>
<style slot-scope>
.settingTemplate .studentInput {
  float: left;
  width: 80%;
}
.settingTemplate .searchStudentBtn {
  float: right;
  width: 20%;
}
.settingTemplate .studentInfoPanel {
  margin-top: 10px;
  padding: 20px;
  background-color: #d3d3d3;
}
.discountItems {
  margin-top: 20px;
}
.courseTemplate {
  margin-top: 20px;
}
.courseTemplate .courseSelect {
  margin-top: 20px;
  width: 30%;
}
.courseTemplate h2 {
  font-size: 16px;
  font-weight: 700;
}
.courseTemplate .courseListTable {
  margin-top: 10px;
}
.courseTemplate .courseListTable .courseNameInput {
  float: left;
  width: 30%;
}
.courseTemplate .courseListTable .termSelect {
  float: left;
  width: 30%;
}
.courseTemplate .courseListTable .commentInput {
  float: right;
  width: 40%;
}
</style>
<style id="printCSS">
@media print {
  body {
    -webkit-print-color-adjust: exact;
  }
}
* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.printArea {
  width: 21cm;
  min-height: 29.7cm;
  padding: 1cm;
  margin: 1cm auto;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: white;
  font-size: 12px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
.printArea .header {
  width: 100%;
  background-color: #fff;
}
.printArea .header .leftPart {
  float: left;
}
.printArea .header .leftPart .companyName {
  color: #2c3a65;
  font-size: 28px;
  line-height: 47px;
}
.printArea .header .rightPart {
  float: right;
}
.printArea .header .rightPart .templateName {
  float: left;
  color: #7a8dc5;
  font-size: 34px;
  font-weight: 700;
  line-height: 48px;
}
.printArea .header .rightPart .logo {
  float: left;
}
.printArea .header .rightPart .logo img {
  width: 60px;
  height: 58px;
  margin-left: 10px;
}
.printArea .header .rightPart .billToDate {
  margin-top: 20px;
  float: right;
}
.printArea .header .rightPart .billToDate .date {
  color: #000;
  text-align: center;
  border: 0.5pt solid #a6a6a6;
  padding: 2px 20px;
}
.printArea .billTo {
  width: 100%;
  margin-top: 40px;
}
.printArea .billTo .title {
  color: white;
  font-size: 13px;
  font-weight: 700;
  text-align: left;
  background: #3b4e87;
  padding-left: 10px;
  width: 35%;
}
.printArea .billTo .name {
  padding-left: 10px;
}

.printArea .items {
  margin-top: 60px;
}
.printArea .items table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  border: 0.5pt solid #3b4e87;
  border-right-width: 0;
}

.printArea .items table tr {
  line-height: 20px;
  height: 20px;
}
.printArea .items table tr th {
  color: white;
  font-size: 13pt;
  font-weight: 700;
  text-align: center;
  background: #3b4e87;
  border-right: 0.5pt solid #3b4e87;
}
.printArea .items table tr td {
  text-align: center;
  border-right: 0.5pt solid #3b4e87;
  font-weight: 400;
}
.printArea .items table tr.odd {
  background: #f2f2f2;
}
.printArea .summary {
  margin-top: 40px;
  width: 100%;
}
.printArea .summary .leftPart {
  float: left;
  width: 45%;
  height: 60px;
  border-collapse: collapse;
  table-layout: fixed;
  border: 0.5pt solid #3b4e87;
}
.printArea .summary .leftPart .title {
  color: white;
  font-size: 13pt;
  font-weight: 700;
  padding-left: 10px;
  background: #3b4e87;
}
.printArea .summary .leftPart ul {
  padding-left: 10px;
  list-style-type: none;
}
.printArea .summary .rightPart {
  float: right;
  width: 40%;
  border-top: 0.5pt solid #3b4e87;
  height: 148px;
}
.printArea .summary .rightPart table {
  width: 100%;
}
.printArea .summary .rightPart table tr td {
  width: 50%;
  font-size: 12pt;
  font-weight: 400;
}
.printArea .summary .rightPart table tr td.value {
  text-align: right;
}
.printArea .summary .rightPart table tr td.discount {
  font-size: 18px;
  font-weight: 700;
}
.printArea .summary .rightPart table tr td.total {
  font-size: 20px;
  font-weight: 700;
}
.printArea .summary .rightPart .paymentMethods {
  width: 100%;
  margin-top: 20px;
  height: 60px;
  border: 0.5pt solid #3b4e87;
  display: none;
}
.printArea .summary .rightPart .paymentMethods .title {
  color: white;
  font-size: 13pt;
  font-weight: 700;
  padding-left: 10px;
  text-align: center;
  background: #3b4e87;
}
.printArea .summary .rightPart .paymentMethods ul {
  padding-left: 10px;
  list-style-type: none;
}
.printArea .footer {
  margin-top: 200px;
  width: 100%;
}
.printArea .footer .container {
  width: 45%;
  padding: 0;
  margin: 0 auto;
  border-width: 0;
  text-align: center;
}
.printArea .footer .contactDetail {
  width: 100%;
}
.printArea .footer .compliment {
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  font-style: italic;
  text-align: center;
}
.printArea .footer::after,
.printArea .summary::after,
.printArea .header::after,
.printArea .header .rightPart .templateAndLog::after {
  content: "";
  display: block;
  height: 0;
  font: 0px/0 serif;
  clear: both;
  overflow: hidden;
}
@page {
  size: A4;
  margin: 0;
}
@media print {
  .printArea {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }
}
</style>
